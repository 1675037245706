//IE10+
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .td-rubber{
    > svg{
      display: none !important;
      color: transparent;
      stroke: transparent;
      background: none;
      border: none;
      &:active{
        display: none !important;
      }
    }
    &:active{
      display: none !important;
    }
  }

  .search_box{
    .form-item.form-type-textfield{
      input{
        height: 39px;
      }
    }
  }

  #lang_dropdown_form_language{
    .dd-select{
      background-color: transparent !important;
    }
  }

  body.front{
    #car-search-front{
      .block__content{
        width: 1040px;
      }
    }
  }
}

//Edge
@supports (-ms-ime-align: auto) {
  #lang_dropdown_form_language{
    .dd-select{
      background-color: transparent !important;
    }
  }

  body.front{
    #car-search-front{
      .block__content{
        width: 1040px;
      }
    }
  }
}